import { RocketmanContextProvder } from "./components/rocketman/RocketmanTransportContext";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { useEffect, useRef, useState } from "react";
import SessionDisconnected from "./components/main/SessionDisconnected";
import rocketmanTransport from "./services/RocketmanTransport";
import useGameHeight from "./hooks/sendGameHeight";
import MainRetail from "./components/Retail/MainRetail";

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const version = urlParams.get("version");
  const isMounted = useRef(false);
  const { width } = useWindowDimensions();
  const [gameDissconnected, setGameDissconnected] = useState(false);
  window.setGameDissconnected = (val) => {
    setGameDissconnected(val);
  }
 
  useGameHeight();

  useEffect(() => {
    rocketmanTransport.desktopResolution = width >= 1024 ? true : false;
  },[]);

  useEffect(() => {
    if (isMounted.current) {
      if (width >= 1024) {
        rocketmanTransport.desktopResolution = true;
      } else if (width < 1024) {
        rocketmanTransport.desktopResolution = false;
      }
    } 
    isMounted.current = true;
  },[width]);


  if (gameDissconnected) {
    return (<SessionDisconnected gameDissconnected={gameDissconnected} />)
  } else {
    return (
        <RocketmanContextProvder>
          <div className="App full-height">
            <MainRetail />
          </div>
        </RocketmanContextProvder>
    );
  }
}

export default App;
