import avatars from "../model/avatars";
import { acceptedLang } from "./Helpers/AcceptedLangs";
import { removeEmpty, sliceObject, sortObjectByKeyDesc } from "./Helpers/ObjectHelper";
import { filterIsolatedBets } from "./IsolatedBets/isolatedBets";
import storage from "./Storage/Storage";
import translate, { languageReinit } from "./Translation/lang";
import TransportAbstract from "./TransportAbstract";
import R7RGSWindowBridge from "./WindowBridge/R7-RGS-WindowBridge";
import { acceptedRegion } from "./Helpers/Acceptedregions";

const MAINTANCE_FILE = '/maintenance.txt'
const MULTIPLIER_HISTORY_LIMIT = 205;
// const SERVER_ERROR_LOG = "https://log.elbet.com";
const NOTIFICATION = {
  FREETICKET: 'FREETICKET',
  PROMOCREDIT: 'PROMOCREDIT',
  JACKPOTWINNER: 'JACKPOTWINNER',
  SOMEONEWONJACKPOT: 'SOMEONEWONJACKPOT',
  TOURNAMENTAWARD: 'TOURNAMENTAWARD',
}

Object.freeze(NOTIFICATION);

var reqBalanceInterval;

class RocketmanTransport extends TransportAbstract {

  /**
   * Singleton RocketmanTransport
   * 
   * @param {*} natsServerOrigin 
   * @param {*} uid 
   * @param {*} serverKeepaliveTimeout 
   * @param {*} secure_conn 
   * @returns 
   */
  constructor(natsServerOrigin, uid, serverKeepaliveTimeout, secure_conn = true, r7RgsWindowBridge) {
    super(natsServerOrigin, uid, serverKeepaliveTimeout, secure_conn);    
    if (RocketmanTransport.instance == null) { 
      this.data = {};
      this.gameStarted = false;
      this.randLengthMultiplier = false;
      this.myCurrency = "";
      this.uncreditedWins = 0;
      this.countryCode = "";
      this.once = false;
      this.zeroOnce = false;
      this.currRound = 0;
      this.onceTriggerRoundCounter = true;
      this.onceTriggerRoundCounterOff = true;
      this.firstLoad = true;
      this.animationLoaded = false;
      this.rocketmanRTP = 0;
      this.maintenenceMode = false;
      this.alreadyInitated = false;
      this.showMainScreen = undefined;
      this.showRetailScreen = undefined;
      this.singleBet = false;
      this.removeJackpotRules = false;
      this.goToMaintenceMode = storage.get('goToMaintenceMode') || null;
      this.showBonusHeader = false;
      this.jackpotValue = 0;
      this.hasCashedOut = false;
      this.jackpotWinsData = null;
      this.setMessagesOnce = false;
      this.messagesCounter = 0;
      this.animation = true;
      this.desktopResolution = true;
      this.clickedStartPlayFreeBets = false;
      this.isolated = false;
      this.isolatedList = [];
      this.myTicketIdentifier = "";
      this.multipliersHistory = {};
      this.allPlayersBetsByRound = [];
      //new launcher params
      this.launcherFullScreen = null;
      this.launcherAutoBet = null;
      this.launcherAutCashout = null;
      this.launcherBackUrl = null;
      this.launcherChatRoom = null;
      this.launcherLanguage = null;
      this.launcherHideHeader = null;
      this.tournamentStatus = 1;
      this.launcherCountryCode = null;
      this.demoAutoBet = true;
      this.launcherCurrency = null; //
      this.launcherUid = null;
      this.launcherCompanyId = null;
      this.launcherCrypto = null;
      this.launcherShowHelp = null;
      this.launcherToken = null; //
      this.launcherVersion = null;
      this.loggedIn = false;
      this.platformId = null;
      this.retailTicketsArr = [];
      this.retail = (uid.retail === "true" || uid.retail === '1' ) ? true : false;
      this.isFlagEnabled = true;
      this.isCurrencyVisible = true;
      this.amountFormat = "";
      this.showInsurancePromo = false;
      this.r7RgsWindowBridge = r7RgsWindowBridge;
      this.r7css = false;
      this.betLimit = false;
      this.useInsurance = false;
      this.setPromoCreditNotif = false;
      this.setShowFreeBetsNotif = false;
      this.branding = '';
      RocketmanTransport.instance = this;
    }
    
    return RocketmanTransport.instance;
  }

  async login(data) {
    console.log("LOGIN", data);
    console.log("Version: v1.0.6");
    //new launcher params
    let tempLang;
    if (data?.launcher_params?.language) {
      tempLang = data?.launcher_params?.language;
    } else {
      tempLang = acceptedLang(urlParams.get('language') || urlParams.get('lang')) ? urlParams.get('language') || urlParams.get('lang') : "en" ;
    }
    if (tempLang === 'rs') {
      tempLang = 'sr';
    } 
    console.log("tempLang",tempLang)
    this.rocketmanRTP = data?.rocketman_rtp;
    
    // TO DO CHECK IN lancherParams for exchange rate data
    this.myExchangeRate = data?.player_data?.currency_exchange_rate || data?.currency_exchange_rate;
    this.platformId = data?.player_data?.platform_id;
    this.loggedIn = true;

    this.launcherFullScreen = data?.launcher_params?.fullscreen || urlParams.get('fullScreen');
    if (data?.open_tickets?.length) {
      this.launcherAutoBet = false;
    } else {
      this.launcherAutoBet =  data?.launcher_params?.auto_bet|| urlParams.get('autobet');
    }
    this.launcherAutCashout = data?.launcher_params?.auto_cashout || urlParams.get('autocashout');
    this.launcherBackUrl = data?.launcher_params?.back_url || urlParams.get('backUrl');
    this.launcherChatRoom = data?.launcher_params?.chat_room || urlParams.get('chatRoom');
    this.launcherLanguage = tempLang.toLowerCase();
    
    this.launcherHideHeader = data?.launcher_params?.hide_header || urlParams.get('hideHeader');
    this.countryCode = data?.launcher_params?.countryCode || urlParams.get('countryCode');
    this.myCurrency = data?.launcher_params?.currency ? data.launcher_params.currency : data?.player_data?.currency_code;
    this.launcherUid = data?.launcher_params?.uid;
    this.launcherCompanyId = data?.launcher_params?.companyId || urlParams.get('companyId');
    this.launcherCrypto = data?.launcher_params?.crypto || urlParams.get('crypto');
    this.launcherShowHelp = data?.launcher_params?.show_help || urlParams.get('showHelp');
    this.launcherToken = data?.launcher_params?.token || urlParams.get('token'); //
    this.launcherVersion = data?.launcher_params?.version || urlParams.get('version'); //

    this.checkConfig(data?.customization);
    // FULLSCREEN ( fullScreen) U PLAYNOW SUBCOMPONENT SEARCH FOR IT
    languageReinit(this.launcherLanguage);
    window.setFullScreen(this.launcherFullScreen);
    try {
      window.setCurrUserMsgLang(this.launcherLanguage);
      window.setMyBetsLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }
    try {
      window.setUserMsgLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }

    try {
      window.setAllBetsTabLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }
    try {
      window.setPreviousBetsTabLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }

    try {
      window.setTopOddsTabLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }

    try {
      window.setHomeLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }

    try {
      window.setBottomLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }

    this.noOfDecimals = Boolean(this.launcherCrypto) === true ? 4 : this.amountFormat !== "" && !/\./.test(this.amountFormat) ? 0 : 2;
    this.isolated = data.isolated;
    this.isolatedList = data?.isolated_list?.split(',');

    // Add multipliers history 
    if (data?.multiplier_history?.length) {
      let tempMpHistory = {};
      try {
        for(let elem in data.multiplier_history) {
          tempMpHistory[Object.keys(data.multiplier_history[elem])[0]] = Object.values(data.multiplier_history[elem])[0];
        }
      } catch (error) {
        console.error("ERROR GETTING HISTORY MULTIPLIERS");
      }
      
      this.multipliersHistory = sortObjectByKeyDesc(tempMpHistory);
      try {
        window.setMultipliersHistory(Object.values(this.multipliersHistory));
        window.setRetailMultipliersHistory(Object.values(this.multipliersHistory));
      } catch (error) {
        //
      }
    }
    
  }

  restartGame() {
    window.location.reload();
  }

  checkConfig(data) {
    //console.log("non paresd data", data)
    if(!data) {
      return
    }
    let parserdData;
    try {
      parserdData = JSON.parse(data);
    } catch (error) {
      console.error("COULD NOT parse config")
    }

    if (typeof parserdData === 'object') {
      // FLAGS
      if ('isFlagEnabled' in parserdData) {
        this.isFlagEnabled = parserdData.flagsEnabled;
      }

      // BRANDING
      if ('branding' in parserdData) {
        if (parserdData.branding) {
          this.branding = parserdData.branding
        }
      }

      // R7RUBY INTEGRATION
      if ('feEvents' in parserdData) {
        if (parserdData.feEvents === true) {
          this.r7RgsWindowBridge.use7RGSWintegration(true);
          this.r7css = true;
        }
      }
      
      // HIDE JACKPOTS RULES
       if ('removeJackpotRules' in parserdData) {
        if (parserdData.removeJackpotRules === true) {
          this.removeJackpotRules = true;
        } 
      }

      // CURRENCY VISIBLE
      if ('isCurrencyVisible' in parserdData) {
        if (parserdData.isCurrencyVisible === false) {
          this.isCurrencyVisible = false;
        } else {
          this.isCurrencyVisible = true;
        }
      }

      // AMOUNT FORMAT
      if ('amountFormat' in parserdData) {
        this.amountFormat = parserdData.amountFormat
      }

    } else {
      console.error("COULD NOT parse config")
    }
  }

  async isCurrRoundActive () {
    return new Promise((res, rej) => {
     var checkRoundActiveInterval = setInterval(() => {
      if (this.currRound) {
        clearInterval(checkRoundActiveInterval);
        res(true);
      }
     },400);
    })
  }


  sessionStatus(data) {
    if (data === "STALE") {
      clearInterval(reqBalanceInterval);
      this.disconnect();
      window.setGameDissconnected(true);
    } 
  }

  toggleMaintenenceMode(data) {
    if (data === true) {
      this.disconnect();
      this.toggleSound(false);
      this.toggleMusic(false);
    }

    window.setMaintenanceMode(data);
  }

  
  lostConn() {
    window.setLostConnection(true);
  }

  connected() {
    window.setLostConnection(false);
  }

  async retailTickets(data) {
    try {
      this.retailTicketsArr = data.tickets;
      window.setRetailTickets(this.retailTicketsArr);
      window.setRetailTicketsLenght(data.total_tickets);
    } catch (error) {
      console.error('cannot set retail tickets')
    }
  }

  retailParachute(shop, win) {
    const randomX = Math.floor(Math.random() * 200 - 100); 
      window.PixiComponent.parachuteAvatar(shop, 
        (win < 0.01 ? "<0.0.1" : 
        win.toFixed(2)) + " " + (this.myCurrency === "ETBB" ? "ETB" : this.myCurrency) , 1.0, randomX, -100,
          avatars[`avatar${Math.floor(Math.random() * 41)}`]);
  }

  setMainShowScreen(screen) {
    this.showMainScreen = screen;
    window.setMainShowScreen('READY_TO_FLY');
  }

  async retailGameStateUpdate(data) {
    if (this.showRetailScreen !== data?.state) {
      this.showRetailScreen = data?.state;
      window.setShowScreen(data?.state);
      //window.setMainShowScreen(data?.state);
      if (typeof data?.bonus !== 'undefined') {
        window.setRetailBonusRound(data?.bonus)
      }
    }

    if (data?.state === 'FLY') {
      let updatedTickets = false; 

        for (let i = 0; i < this.retailTicketsArr.length; i++) {
            const ticket = this.retailTicketsArr[i];
            if (data.mp >= ticket.Multiplier && !ticket.CashedOut) {
                ticket.CashedOut = true;
                let win;
                if(this.myCurrency?.toUpperCase() === ticket?.CurrencyID?.toUpperCase()) {
                  win = ticket.Multiplier * ticket.Stake;
                } else {
                  win = ticket.Multiplier * (ticket.StakeEUR * this.myExchangeRate);
                }
                
                ticket.Win = win;
                const randomX = Math.floor(Math.random() * 250 - 125);
                setTimeout(() => {
                    window.PixiComponent.parachuteAvatarRetail(
                        ticket.Shop,
                        (win < 0.01 ? "<0.1" : 
                        win.toFixed(2)) + " " + (this.myCurrency === "ETBB" ? "ETB" : this.myCurrency),
                        1.0,
                        randomX,
                        40
                    );
                }, [(i + 1) * 100]);
                updatedTickets = true; // Set the flag to indicate ticket update
            }
        }

        if (updatedTickets) {
            window.setRetailTickets(this.retailTicketsArr);
        }
    }
    
      if (!this.gameStarted) {
        rocketmanTransport.requestJackpotData(this.launcherCompanyId);
        window.setGameStarted(true);
        window.setInitialGameStart(true);
        this.gameStarted = true;
      }

    this.data = data;
    if (data.rnd !== this.currRound) {
      this.currRound = data.rnd;
      window.setCurrRound(this.currRound);
    }

    // if (data.end) {
    //   if (this.onceSnapFlag) { // prevent for loop setting snapped state
    //     this.onceSnapFlag = false;
    //   }
    // }

    if (data.mp === 1 && data.end === false && data.rs === 0) {
      if (this.onceTriggerRoundCounter) {
        this.onceTriggerRoundCounter = false;
        try {
          window.setShowRoundCounter(true);
        } catch (error) {
          //
        }
        
      }
    }

    if (data.rs === 1 && data.end === false) {
      if (this.onceTriggerRoundCounterOff) {
        this.onceTriggerRoundCounterOff = false;
        try {
          window.setShowRoundCounter(true);
        } catch (error) {
          //
        }
      }
    }

    if (data.end === true ) {
      this.onceTriggerRoundCounter = true;
      this.onceTriggerRoundCounterOff = true;
      // Set Multiplier History
      if (this.onceFillHistory) {
        const delay = setTimeout(() => {     
          this.getJackpotWins();
          clearTimeout(delay);
        },4500)
        // this.retailTicketsArr = [];
        // window.setRetailTickets(this.retailTicketsArr);
        // window.setRetailTicketsLenght(0);
        this.onceFillHistory = false;
        // Clean History Multipliers only latest by Multipliers
        this.multipliersHistory[this.currRound] = data.mp;
        this.randLengthMultiplier = !this.randLengthMultiplier;
        const tempMultiplierLength = Object.keys(this.multipliersHistory).length;
        if (tempMultiplierLength >= MULTIPLIER_HISTORY_LIMIT) {
          this.multipliersHistory = sliceObject({...this.multipliersHistory}, tempMultiplierLength - MULTIPLIER_HISTORY_LIMIT - 1 - Number(this.randLengthMultiplier), tempMultiplierLength);
        }
        try {
          window.setMultipliersHistory(Object.values(this.multipliersHistory));
          window.setRetailMultipliersHistory(Object.values(this.multipliersHistory));
        } catch (error) {
          //
        }
        
        if (this.gameStarted) {
          window.PixiComponent.hideParachuteAvatars();
        }
      }
    }

    if (data.end === false /* && data.rs === 0 */) { 
      this.onceFillHistory = true;
      // if (!this.onceSnapFlag) { 
      //   //window.setRocketSnapped(false);
      //   //window.setEndedAndCashouted(false);
      //   this.onceSnapFlag = true;
      // }
    }

    //window.setMp(data.mp);
  }

  async checkMaintenceMode() {
    const response = await (await fetch(MAINTANCE_FILE)).text();
    // waits until the request completes...
    return Boolean(Number(response));
  }

  tickBets(data) {
    Object.keys(data).forEach(ticketBet => {
      let ticket = data[ticketBet]
      ticket.ticket_id = ticketBet;
      ticket.bet = ticket.currency_code == this.myCurrency ? ticket.bet : Number(Number(ticket.bet_eur) * Number(this.myExchangeRate)).toFixed(this.noOfDecimals);
      ticket.win = null;
      ticket.win_eur = null;
      if (!(ticketBet in this.allPlayersBetsByRound)) {
        this.allPlayersBetsByRound[ticketBet] = ticket;
      } else {
        //console.log("ALREADY IN")
      }
    });
    this.allPlayersBetsByRound = removeEmpty(this.allPlayersBetsByRound);
    window.setAllPlayerBets(this.allPlayersBetsByRound);
  }

  tickCanceledBets(data) {
    Object.keys(data).forEach(ticketBet => {
      delete this.allPlayersBetsByRound[ticketBet];
    });
    this.allPlayersBetsByRound = removeEmpty(this.allPlayersBetsByRound);
    window.setAllPlayerBets(this.allPlayersBetsByRound);
  }

  exception(data) {
    window.setWarning(data?.desc);
  }

  localErrors(data) {
    window.setWarning(data);
  }

  // roundMpByHash(data) {
  //   window.setShowRoundMP(data);
  // }

  ticketDetails(data) {
    try {
      window.setTicketDetails(data);
    } catch (error) {
      //console.log("BET INFO NOT ACTIVE")
    } 
  }
 
  tickCashouts(data) {
    for (const cashout of Object.keys(data)) {
      if ( !(cashout in this.allPlayersBetsByRound) ) {
        console.log("CASHOUT ID NOT FOUND");
      } else {
        this.allPlayersBetsByRound[cashout].win = this.allPlayersBetsByRound[cashout].currency_code == this.myCurrency ? data[cashout].win_in_player_currency : Number(data[cashout].win_eur) * Number(this.myExchangeRate);
        this.allPlayersBetsByRound[cashout].win_eur = data[cashout].win_eur;
        const ticketData = this.allPlayersBetsByRound[cashout];
        if (filterIsolatedBets(ticketData)) {
          const randomX = Math.floor(Math.random() * 200 - 100); // -100 do 100
          let parachuteText = ticketData.win < 0.01 ? "<0.01" : ticketData.win.toFixed(this.noOfDecimals)
          parachuteText += this.isCurrencyVisible ? " " + (this.myCurrency === "ETBB" ? "ETB" : this.myCurrency) : ""
          window.PixiComponent.parachuteAvatar(ticketData.username, parachuteText, 1.0, randomX, -100, avatars[`avatar${ticketData.avatar_id}`]);
        } 
      }
    }
    window.setAllPlayerBets(this.allPlayersBetsByRound);
    
    this.cashoutTick = !this.cashoutTick;
    window.setCashoutTick(this.cashoutTick);
  }

  jackpotData(data) {
    window.setJackpotData(data);
    window.setHeaderJackpotData(data);
  }

  jackpotHistory(data) {
    if (data.length) {
      switch (data[0]?.jackpot_level) {
        case 1:
          window.setJackpotHistory(data);  
          break;
        case 2:
          window.setRocketPotHistory(data);  
          break;
        case 3:
          window.setBoosterPotHistory(data);  
        break;   
        default:
          break;
      }
    }
  }

  setHistoryTabIndex(index) {
    this.historyTabIndex = index;
  }

  showPromoCreditNotification(msg) {
    const numbers = msg.match(/[+-]?\d+(?:\.\d+)?/g).map(String);
    if (numbers.length) {
      
      this.requestUserBalanceInAbstract();
      this.lastBalanceRequestTime = new Date().getTime();
      this.promoBalance = numbers[0];
      window.setPromoBalance(this.promoBalance);
      window.setPromoCreditVal(numbers[0]);
      if (this.promoBalance >= this.minBet) {
        window.PixiComponent.promoAstronaut("Promo credit:", msg, 1.7);
        
        this.setPromoCreditNotif = true;
        window.setAstroPromoCredit(numbers[0]);
      }
    }
  }

  showJackpotNotification(msg) {
    const numbers = msg.match(/[+-]?\d+(?:\.\d+)?/g).map(String);
    const valute = msg.match(/[a-zA-Z]+/).map(String);

      let jackpotLevel, jackpotMoney, jackpotValute;

      if (!valute) {
        jackpotValute = '';
      } else {
        if (!valute.length) {
          jackpotValute = '';
        } else {
          jackpotValute = valute[0];
        }
      }

      numbers.forEach((number) => {
        if (number.indexOf(".") === -1) {
          jackpotLevel = number;
        } else {
          jackpotMoney = number;
        }
      });

      this.jackpotValue += Number(jackpotMoney);

      window.setJackpotValue(jackpotMoney);
      window.setShowJackpotPanel(jackpotLevel);
  }

  showSomeOneWonJackpot(msg) {
    const strings = msg.split(';').map(String);

      let jackpotLevel, jackpotMoney, jackpotName, jackpotType, message;
  
      if (strings) {
        if (strings.length > 2) {
          jackpotLevel = strings[0];
          jackpotMoney = strings[1];
          jackpotName = strings[2];

         if (Number(jackpotLevel) === 1) {
          jackpotType = 'Jackpot';
         } 

         if (Number(jackpotLevel) === 2) {
          jackpotType = 'Rocketpot';
         } 

         if (Number(jackpotLevel) === 3) {
          jackpotType = 'Boosterpot';
         }

         message = `${jackpotType} ${translate('winner')} ${jackpotName} ${translate('wins')} ${Number(Number(jackpotMoney) * Number(this.myExchangeRate)).toFixed(this.noOfDecimals)} ${this.isCurrencyVisible ? this.myCurrency : ""}`;
         this.localErrors(message);
        }
      }
  }

  jackpotWins(data) {
    this.jackpotWinsData = data;
  }


  notification(data) {
    switch (data.type) {
      case NOTIFICATION.FREETICKET:
        this.showFreeTicketsNotification(data.msg);
        break;
      case NOTIFICATION.PROMOCREDIT:
        this.showPromoCreditNotification(data.msg);
        break;
      case NOTIFICATION.TOURNAMENTAWARD:
        this.showTournamentWinnerNotification(data.msg);
        break;
      case NOTIFICATION.JACKPOTWINNER:
        this.showJackpotNotification(data.msg);
        break;
      case NOTIFICATION.SOMEONEWONJACKPOT:
        this.showSomeOneWonJackpot(data.msg);
        break;
      default:
        break;
    }
  }
}

 export const urlParams = new URLSearchParams(window.location.search);

 const uid = urlParams.get('uid');
 const companyId = urlParams.get('companyId');
 const token = urlParams.get('token');
 const currency = urlParams.get('currency');
 export let language = acceptedLang(urlParams.get('language') || urlParams.get('lang')) ? urlParams.get('language') || urlParams.get('lang') : "en";
 const backUrl = urlParams.get('backUrl');
 const chatRoom = urlParams.get('chatRoom');
 const countryCode = urlParams.get('countryCode');
 const autoBet = urlParams.get('autobet');
 const autoCashout = urlParams.get('autocashout');
 const hideHeader = urlParams.get('hideHeader');
 const launchId = urlParams.get('launchId');
 const userDefinedUsername = storage.get('userDefinedUsername') || '';
 const version = urlParams.get('version');
 const region = acceptedRegion(urlParams.get('region')) ? urlParams.get('region') : 'default';
 const retail = urlParams.get('region') ==  'retail' ? '1' : '0';

 if (language === 'rs') {
  language = 'sr';
}

let platformParams = {userDefinedUsername: userDefinedUsername, companyId: companyId || 1, launchId, uid: uid || String(new Date().valueOf()),
   token, currency, language, backUrl, chatRoom, countryCode, autoBet, autoCashout, hideHeader, version, region, retail};
const r7RgsWindowBridge = new R7RGSWindowBridge();
const rocketmanTransport = new RocketmanTransport("", platformParams, 1000, false,r7RgsWindowBridge);
export default rocketmanTransport;
