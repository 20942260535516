import * as React from "react";
import * as PIXI from "pixi.js-legacy";
import * as TWEEN from "tween.js";
import WebFont from "webfontloader";
import rocketmanTransport from "../../services/RocketmanTransport";
import mpHexColorHelper from "../../services/Helpers/HexColorHelper";

class PixiView {
  constructor(react) {
    console.log("GameView init")
    var TEXTURES = [
        "fonts/TitilliumWeb-Bold.ttf",
        "tex/rocket.png",
        "tex/rocketPlatform1.png",
        "tex/rocketPlatform2.png",
        "tex/meter.png",
        "tex/earth.png",
        "tex/flame.png",
        "tex/explosion.png",
        "tex/fog.png",
        "tex/fog2.png",
        "tex/endplanet.png",
        "tex/stars/star_glow.png",
        "tex/stars/star_blue.png",
        "tex/stars/star_orange.png",
        "tex/stars/star_pink.png",
        "tex/stars/star_purple.png",
        "tex/stars/star_vector.png",
        "tex/stars/star_white.png",
        "tex/retail-planets/planet1/(1).png",
        "tex/retail-planets/planet1/(2).png",
        "tex/retail-planets/planet1/(3).png",
        "tex/retail-planets/planet2/(1).png",
        "tex/retail-planets/planet2/(2).png",
        "tex/retail-planets/planet2/(3).png",
        "tex/retail-planets/planet3/(1).png",
        "tex/retail-planets/planet3/(2).png",
        "tex/retail-planets/planet3/(3).png",
        "tex/retail-planets/planet4/(1).png",
        "tex/retail-planets/planet4/(2).png",
        "tex/retail-planets/planet4/(3).png",
        "tex/retail-planets/planet4/(4).png",
        "tex/retail-planets/planet4/(5).png",
        "tex/retail-planets/planet5/(1).png",
        "tex/retail-planets/planet5/(2).png",
        "tex/retail-planets/planet5/(3).png",
        "tex/retail-planets/planet6/(1).png",
        "tex/retail-planets/planet6/(2).png",
        "tex/retail-planets/planet6/(3).png",
        "tex/retail-planets/planet7/(1).png",
        "tex/retail-planets/planet7/(2).png",
        "tex/retail-planets/planet7/(3).png",
        "tex/retail-planets/planet7/(4).png",
        "tex/retail-planets/planet7/(5).png",
        "tex/astronaut.png",
        "tex/astronaut_label.png",
      ];
    
    const COLORED_STARS = [
      "tex/stars/star_blue.png",
      "tex/stars/star_blue.png",
      "tex/stars/star_orange.png",
      "tex/stars/star_pink.png",
      "tex/stars/star_purple.png",
      "tex/stars/star_white.png",
    ];
    let asetsLoadedOnce = false;
    let DISPLAY_SCALE = 1; //- (window.devicePixelRatio/10)
    const BACKGROUND_SPEED = -5;
    const STAR_SPAWN_OFFSET = 3000;
    const COLORED_STARS_OFFSET = react.gameCanvas.height;
    const DARKEN_TIME = 6000;
    const METER_SPEED = 5;
    const playNowHolderElem = document.querySelector(".play-now-page-holder");
    const urlParams = new URLSearchParams(window.location.search);
    const version = urlParams.get("version");
    const canvas = react.gameCanvas;
    let secondsElapsed = 0;
    let text;
    let gameState = null;
    let currMp = null;
    let gameStarted = false;
    let fontLoaded = false;
    let lastStarRepositionCheck = 0;
    var MP_TEXT_OFFSET = 0;
    var ROCKET_LAUNCHED_POSITION;
    var retailAvatarTexture = PIXI.Texture.from('/retail-avatars/avatar-1.png');
  
    var branding = "";
    var brandingLoaded = false;

    if (window.innerWidth >= 1920) {
      DISPLAY_SCALE = 2;
    } else {
      DISPLAY_SCALE = 1.5;
      }
    // ROCKET_LAUNCHED_POSITION = -100;
  

    PIXI.settings.RESOLUTION = 0;
    PIXI.settings.FILTER_RESOLUTION = 0;

    // INIT PIXI
    PIXI.utils.skipHello();
    this.app = new PIXI.Application({
      antialias: false,
      legacy: true,
      backgroundAlpha: true,
      useContextAlpha: false,
      autoDensity: false,
      resolution: 0.8,
      forceCanvas: true,
      width: canvas.width,
      height: canvas.height,
      // backgroundColor: 0x4588d6, 0xFFC0CB
      backgroundColor: 0x2e7dd9,
    });
    var app = this.app;
    app.parachutingAvatars = [];
    canvas.appendChild(this.app.view);

    // LOAD TEXTURES
    if (!assetsLoaded) {
      TEXTURES.forEach((tex) => app.loader.add(tex, tex));
      for (let i = 1; i < 3; i++) {
        app.loader.load("tex/retail-flame/flame (" + i + ").png");
      }    
    }
    // LOAD BRANDING
    function loadBranding(name) {
      app.renderer.backgroundColor = 0xFFC0CB;
      app.loader.add("tex/bet-construct/mr-first.png");
      app.loader.add("tex/bet-construct/bahamut_fish.png");
      for (let i = 1; i < 12; i++) {
        app.loader.load("tex/bet-construct/coins/coin" + i + ".png");
      }

      for (let i = 1; i < 38; i++) {
        app.loader.load("tex/bet-construct/eye/eye (" + i + ").png")
      }
    }
    // LOAD FONT
    WebFont.load({
      custom: {
        families: ["Conv_TitilliumWeb-Bold"],
        urls: ["fonts.css"],
      },
      active: () => {
        fontLoaded = true;
      },
      fontloading: () => {
        fontLoaded = true;
      }
    });

    // SETUP TWEEN.JS
    function animate(time) {
      requestAnimationFrame(animate);
      TWEEN.update(time);
    }
    requestAnimationFrame(animate);

    app.loader.onComplete.add((r) => {assetsLoaded = true})
    // MAIN GAMELOOP
    app.ticker.add((delta) => {
      if (rocketmanTransport?.data?.mp !== undefined) {
        secondsElapsed += (1 / 60) * delta;
        changeMp(parseFloat(rocketmanTransport?.data?.mp).toFixed(2));
      }
      if (!assetsLoaded || !canvas || canvas.offsetParent == null) {
        // console.log(assetsLoaded, canvas, canvas.offsetParent)
        return;
      }
      branding = rocketmanTransport.branding;
      if(!brandingLoaded && branding) {
        brandingLoaded = true;
        loadBranding(branding);
      }
      canvas.width =
        version === "desktop" && window.innerWidth >= 1024
          ? playNowHolderElem.offsetWidth
          : window.innerWidth;
      canvas.height =
        version === "desktop" && window.innerWidth >= 1024
          ? playNowHolderElem.offsetHeight
          : window.innerHeight;
      if (canvas.width < 10) {
        return;
      }
      if (app.renderer.screen.width !== canvas.width) {
        app.renderer.resize(canvas.width, canvas.height);
        app.stage.x = canvas.width / 2;
        app.stage.y = canvas.height / 2;
        if (canvas.height < 600){
          app.stage.scale.set(.6, .6);
          ROCKET_LAUNCHED_POSITION = -80
          MP_TEXT_OFFSET = 40
        }else{
          app.stage.scale.set(DISPLAY_SCALE, DISPLAY_SCALE);
        }
      }
      // FIND GAME VIEW STATE
      if (rocketmanTransport?.data?.mp !== undefined) {
        if (rocketmanTransport?.data?.end === true) {
          if (parseInt(rocketmanTransport?.data?.mp) == 20000) {
            gameState = "LANDED";
          } else {
            gameState = "CRASHED";
          }
        } else if (rocketmanTransport?.data?.mp > 1) {
          gameState = "LAUNCHED";
        } else {
          gameState = "BEGIN";
        }
      }

      // SETUP GAME ONCE IF EVERYTHING IS LOADED
      // NOTE TODO: could a ticker be added and remove itself once setupGame is called, then gameStarted won't be checked every tick and can be removed.
      if (!gameStarted && fontLoaded && currMp != null) {
        gameStarted = true;
        setupGame();
        if (assetsLoaded && !asetsLoadedOnce) {
          asetsLoadedOnce = true;
          rocketmanTransport.animationLoaded = true;
        } 
      }
    });

    // SETUP GAME
    function setupGame() {
      // defines each game object and adds it's behaviour to ticker
      // NOTE TODO REFACTOR try mimicking what was done for planets

      // RocketMoveAround
      let rocketMoveRight = null
      let rocketMoveLeft = null
      let rocketRotateRight = null
      let rocketRotateLeft = null
      function rocketMoveLeftRight(){
        let leftPosX = randRange(0,-60)
        let rightPosX = randRange(0,60)
        let animTime = 2000-Math.min(currMp,1500)
        rocketMoveLeft = new TWEEN.Tween(rocket.position)
                  .to({ x: leftPosX }, animTime)
                  .easing(TWEEN.Easing.Quadratic.InOut)
                  .onComplete(rocketMoveLeftRight)
        rocketMoveRight = new TWEEN.Tween(rocket.position)
                  .to({ x: rightPosX }, animTime)
                  .easing(TWEEN.Easing.Quadratic.InOut)
                  .chain(rocketMoveLeft)
                  .start()
        rocketRotateRight = new TWEEN.Tween(rocket)
                  .to({ angle: -2 }, animTime/3)
                  .easing(TWEEN.Easing.Quadratic.InOut)
                  .delay(animTime-animTime/3)
        rocketRotateLeft = new TWEEN.Tween(rocket)
                  .to({ angle: 2 }, animTime/3)
                  .easing(TWEEN.Easing.Quadratic.InOut)
                  .chain(rocketRotateRight)
                  .start()
      }
      function rocketStopMovingLeftRight(){
        [rocketMoveRight, rocketMoveLeft, rocketRotateRight, rocketRotateLeft].forEach(function(tw){
          try{
            tw.stop()
          }catch{}
        });
        rocket.angle = 0
      }
      

      let lastTickGameState = null;
      // STATE CHANGE HANDLER
      app.ticker.add((delta) => {
        if (lastTickGameState !== gameState) {
          lastTickGameState = gameState;

          if (gameState === "BEGIN") {
            // app.renderer.backgroundColor = 0x4588d6;
            app.renderer.backgroundColor = branding ? 0xFFC0CB : 0x2e7dd9;
            darkenTween.stop();
            // lightenTween.start()
            flame.scale.set(0);
            // show rocket
            new TWEEN.Tween(rocket.scale)
              .to({ x: 0.5, y: 0.5 }, 300)
              .easing(TWEEN.Easing.Linear.None)
              .start();
            // hide endplanet
            new TWEEN.Tween(endplanet.scale)
              .to({ x: 0, y: 0 }, 250)
              .easing(TWEEN.Easing.Linear.None)
              .start();
            // reset rocket pos
            new TWEEN.Tween(rocket.position)
              .to({ y: 0, x: 0 }, 300)
              .easing(TWEEN.Easing.Linear.None)
              .start();
            new TWEEN.Tween(rocket)
              .to({ angle: 0 }, 300)
              .easing(TWEEN.Easing.Linear.None)
              .start();
            // show earth
            new TWEEN.Tween(earthContainer.position)
              .to({ y: -scrollContainer.position.y }, 150)
              .easing(TWEEN.Easing.Linear.None)
              .start();
          }
          if (gameState === "LANDED") {
            endplanet.position.y = 1000;
            // hide flame
            darkenTween.stop();
            new TWEEN.Tween(flame.scale)
              .to({ x: 0, y: 0 }, 700)
              .easing(TWEEN.Easing.Quadratic.In)
              .start();
            // move rocket pos
            new TWEEN.Tween(rocket.position)
              .to({ y: -50 }, 800)
              .easing(TWEEN.Easing.Quadratic.InOut)
              .start();
            // show endplanet
            new TWEEN.Tween(endplanet.scale)
              .to({ x: 0.5, y: 0.5 }, 800)
              .easing(TWEEN.Easing.Quadratic.Out)
              .start();
            // show endplanet
            new TWEEN.Tween(endplanet.position)
              .to({ y: rocket.position.y + rocket.height * 1.75 }, 800)
              .easing(TWEEN.Easing.Quadratic.Out)
              .start();
          }
          if (gameState === "CRASHED") {
            // hide rocket
            darkenTween.stop();
            rocket.scale.set(0);
            explosion.scale.set(1);
            explosion.gotoAndPlay(0);
          }
          if (gameState === "LAUNCHED") {
            scrollContainer.position.y -= BACKGROUND_SPEED * delta;
            darkenTween.start();
            rocket.scale.set(0.5);
            // show flame
            new TWEEN.Tween(flame.scale)
            .to({ x: 2, y: 2 }, 300)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .start();
            new TWEEN.Tween(rocket.position)
              .to({ y: ROCKET_LAUNCHED_POSITION }, 1000)
              .easing(TWEEN.Easing.Quadratic.Out)
              .onComplete(rocketMoveLeftRight)
              .start();
          }else{
            rocketStopMovingLeftRight()
          }
        }
      });
      
      // METER
      const amountOfMeterElements = 20;
      let meter = null;
      const meterContainerOffset = 1000;
      const meterContainer = new PIXI.Container();
      meterContainer.position.y = -100;
      app.stage.addChild(meterContainer);

      for (let i = 0; i < amountOfMeterElements; i++) {
        meter = addSprite("tex/meter.png", meterContainer, 0, 0, 0.7);
        var meterText = new PIXI.Text(String(currMp), {
          fontFamily: "Conv_TitilliumWeb-Bold",
          fontSize: 25,
          fill: 0xffffff,
          align: "center",
        });
        meterText.text = predictMultiplier(i * 2) + "x";
        meterText.alpha = 0.6;
        meterText.position.set(
          meter.width * 0.9,
          meter.height * meter.scale.y
        );
        meterText.anchor.y = 0.5;
        meter.addChild(meterText);
        meter.textElement = meterText;
        meter.position.y = -meter.height * i;
      }
      let lastMeterRepositionCheck = 0;
      let movedElementCounter = amountOfMeterElements;
      app.ticker.add((delta) => {
        meterContainer.position.x =
          (-app.screen.width / 2 + app.screen.width / 10) / app.stage.scale.x;
        if (gameState === "LAUNCHED") {
          meterContainer.position.y += METER_SPEED * delta;
        }
        if (secondsElapsed - lastMeterRepositionCheck > 0.1) {
          if (gameState === "BEGIN" && meterContainer.position.y !== 100) {
            meterContainer.position.y = -100;
            for (let i = 0; i < amountOfMeterElements; i++) {
              let checkChild = meterContainer.children[i];
              checkChild.position.y = -meter.height * i;
              checkChild.textElement.text = predictMultiplier(i * 1.2) + "x";
              movedElementCounter = amountOfMeterElements;
            }
            // checkChild.textElement.text = predictMultiplier(amountOfMeterElements/2) + "x"
          } else {
            lastMeterRepositionCheck = secondsElapsed;
            const checkChild =
              meterContainer.children[
                movedElementCounter % amountOfMeterElements
              ];
            if (
              meterContainer.position.y +
                checkChild.position.y -
                meterContainerOffset >
              0
            ) {
              checkChild.position.y -=
                checkChild.height * amountOfMeterElements;
              checkChild.textElement.text =
                predictMultiplier(amountOfMeterElements / 2) + "x";
              movedElementCounter += 1;
            }
          }
        }
      });

      // FOG
      const fogContainer = new PIXI.Container();
      fogContainer.alpha = 0;
      app.stage.addChild(fogContainer);
      const fog = addSprite("tex/fog.png", fogContainer, 0, 0, 2);
      const fog2 = addSprite("tex/fog2.png", fogContainer, 0, -fog.height, 2);

      app.ticker.add((delta) => {
        if (gameState === "LAUNCHED") {
          fogContainer.position.y -= BACKGROUND_SPEED * 0.5 * delta;
        }
        if (secondsElapsed - lastStarRepositionCheck > 1) {
          // let lastFogRepositionCheck = secondsElapsed
          if (
            fogContainer.position.y + fog.position.y + fog.height / 5 >=
            fog.height
          ) {
            fog.position.y -= fog.height * 2;
          }
          if (
            fogContainer.position.y + fog2.position.y + fog2.height / 5 >=
            fog2.height
          ) {
            fog2.position.y -= fog2.height * 2;
          }
        }
      });

      // FOG WIGGLE
      const displacementSprite = PIXI.Sprite.from(
        "tex/displacement_map_repeat.png"
      );
      // Make sure the sprite is wrapping.
      displacementSprite.texture.baseTexture.wrapMode =
        PIXI.WRAP_MODES.REPEAT;
      const displacementFilter = new PIXI.filters.DisplacementFilter(
        displacementSprite
      );
      displacementFilter.padding = 10;
      app.stage.addChild(displacementSprite);
      // TODO NOTE: line below closes the fog "twitch" on game start
      fogContainer.filters = [displacementFilter];
      displacementFilter.scale.x = 150;
      displacementFilter.scale.y = 150;
      app.ticker.add(() => {
        displacementSprite.y = fogContainer.y;
        displacementSprite.x++;
        // Reset x to 0 when it's over width to keep values from going to huge numbers.
        if (displacementSprite.x > displacementSprite.width) {
          displacementSprite.x = 0;
        }
      });

      fogContainer.alpha = 1;

      // SCROLL CONTAINER
      const scrollContainer = new PIXI.Container();
      app.stage.addChild(scrollContainer);
      scrollContainer.width = fog.width;
      app.ticker.add((delta) => {
        if (gameState === "LAUNCHED") {
          scrollContainer.position.y -= BACKGROUND_SPEED * delta;
        }
      });
      // EXPOSE
      app.scrollContainer = scrollContainer;

      // ENDPLANET
      const endplanet = addSprite(
        "tex/endplanet.png",
        app.stage,
        0,
        app.stage.height,
        0
      );
      
      // ROCKET
      const rocket = addSprite("tex/rocket.png", app.stage, 0, 0, 0.5);
      let rocket_engine_sound = null;

      let w = parseInt(833 / 6);
      let h = parseInt(821 / 4);
      let flameAnimationFrames = [];

      for (let i = 1; i < 16; i++) {
        flameAnimationFrames.push(
          new PIXI.Texture.from("tex/retail-flame/flame (" + i + ").png")
        );
      }
      
      let flame = new PIXI.AnimatedSprite(flameAnimationFrames);
      flame.anchor.x = 0.5;
      flame.anchor.y = 0;
      flame.position.y = rocket.height / 1.4;
      flame.loop = true;
      rocket.addChild(flame);
      flame.animationSpeed = 0.4;
      flame.scale.set(2);
      
      flame.play();

      w = parseInt(2242 / 7);
      h = parseInt(1736 / 5);
      let explosionAnimationFrames = [];
      const explosionSpritesheet = new PIXI.BaseTexture.from(
        "tex/explosion.png"
      );
      for (let z = 0; z < 5; z++) {
        for (let i = 0; i < 6; i++) {
          explosionAnimationFrames.push(
            new PIXI.Texture(
              explosionSpritesheet,
              new PIXI.Rectangle(i * w, z * h, w, h)
            )
          );
        }
      }
      let explosion = new PIXI.AnimatedSprite(explosionAnimationFrames);
      explosion.anchor.set(0.5);
      explosion.loop = false;
      app.stage.addChild(explosion);
      explosion.position.y = -70;
      explosion.animationSpeed = 0.3;

      new TWEEN.Tween(explosion.position)
        .to({ x: 5 }, 150)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .yoyo()
        .repeat(Infinity)
        .start();
      new TWEEN.Tween(explosion.position)
        .to({ y: -65 }, 100)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .yoyo()
        .repeat(Infinity)
        .start();
      explosion.scale.set(0);
      explosion.onComplete = function () {
        explosion.scale.set(0);
        explosion.stop();
      };

      // MP
      text = new PIXI.Text("1.00", {
        fontFamily: "Conv_TitilliumWeb-Bold",
        fontSize: 200,
        fill: 0xffffff,
        align: "center",
      });
      text.anchor.set(0.5);
      text.scale.set(0.5);
      app.stage.addChild(text);
      app.ticker.add((delta) => {
        text.position.y =
          ((-app.screen.height + app.screen.height / 1.4) / app.stage.scale.y)+MP_TEXT_OFFSET;
      });

      let darkenTween;
      // DAYNIGHT
      if (branding) {
        darkenTween = new TWEEN.Tween(app.renderer._backgroundColorRgba)
        .to([12 / 255, 15 / 255, 50 / 255], DARKEN_TIME)
        .easing(TWEEN.Easing.Linear.None);
      } else {
        darkenTween = new TWEEN.Tween(app.renderer._backgroundColorRgba)
        .to([14 / 255, 19 / 255, 64 / 255], DARKEN_TIME)
        .easing(TWEEN.Easing.Linear.None);
      }
      

      // STARS
      let allStars = [];

      // REGULAR STARS
      for (let i = 0; i < 30; i++) {
        const randomSkyPosX = randRange(
          -fog.width / 2 + 10,
          fog.width / 2 - 10
        );
        const randomSkyPosY = randRange(
          rocket.position.y,
          rocket.position.y - STAR_SPAWN_OFFSET
        );

        const starContainer = new PIXI.Container();
        starContainer.position.set(randomSkyPosX, randomSkyPosY);
        scrollContainer.addChild(starContainer);

        const starGlowScale = randRange(0.4, 0.8);

        const starGlow = addSprite(
          "tex/stars/star_glow.png",
          scrollContainer,
          0,
          0,
          starGlowScale
        );
        const star = addSprite(
          "tex/stars/star_white.png",
          scrollContainer,
          0,
          0,
          randRange(0.2, starGlowScale - 0.3)
        );

        breath(star, star.scale.x * 1.2, 2000, TWEEN.Easing.Quadratic.Out);
        breath(
          starGlow,
          starGlow.scale.x * 1.2,
          1000,
          TWEEN.Easing.Quadratic.Out
        );

        starContainer.addChild(starGlow);
        starContainer.addChild(star);

        allStars.push(starContainer);
      }

      // VECTOR STARS
      for (let i = 0; i < 5; i++) {
        const randomSkyPosX = randRange(
          -fog.width / 2 + 10,
          fog.width / 2 - 10
        );
        const randomSkyPosY = randRange(
          rocket.position.y,
          rocket.position.y - STAR_SPAWN_OFFSET
        );

        const starContainer = new PIXI.Container();
        starContainer.position.set(randomSkyPosX, randomSkyPosY);
        scrollContainer.addChild(starContainer);

        const starGlowScale = randRange(0.8, 1.25);

        const starGlow = addSprite(
          "tex/stars/star_glow.png",
          scrollContainer,
          0,
          0,
          starGlowScale
        );
        const star = addSprite(
          "tex/stars/star_vector.png",
          scrollContainer,
          0,
          0,
          randRange(0.5, starGlowScale - 0.2)
        );

        starContainer.addChild(starGlow);
        starContainer.addChild(star);

        allStars.push(starContainer);
      }

      // COLORED STARS
      for (let i = 0; i < 10; i++) {
        const randomUpperHalfSkyPosX = randRange(
          -fog.width / 2 + 10,
          fog.width / 2 - 10
        );
        const randomUpperHalfSkyPosY = randRange(
          rocket.position.y - COLORED_STARS_OFFSET,
          rocket.position.y - STAR_SPAWN_OFFSET - COLORED_STARS_OFFSET
        );

        const starContainer = new PIXI.Container();
        starContainer.position.set(
          randomUpperHalfSkyPosX,
          randomUpperHalfSkyPosY
        );
        scrollContainer.addChild(starContainer);

        const starType = randomChoice(COLORED_STARS);
        const star = addSprite(
          starType,
          scrollContainer,
          0,
          0,
          randRange(0.3, 0.8)
        );
        breath(
          star,
          star.scale.x * randRange(0.6, 1.2),
          1000,
          TWEEN.Easing.Quadratic.Out
        );

        starContainer.addChild(star);

        allStars.push(starContainer);
      }

      // PLANETS
      let lastPlayedSecond = 0;
      app.ticker.add((delta) => {
        const currSecond = parseInt(secondsElapsed);
        if (currSecond !== lastPlayedSecond && currSecond % 5 == 0) {
          lastPlayedSecond = currSecond;
        }
      });

      allStars.push(
        planet1(scrollContainer, fog.width / 2 + 70, rocket.position.y - 300)
      );
      if (branding) {
        allStars.push(
          bahamutFish(
            scrollContainer,
            -fog.width / 2 - 70,
            rocket.position.y - 200
          )
        );
        allStars.push(
          bahamutFish(
            scrollContainer,
            fog.width / 2 + 70,
            rocket.position.y - 800
          )
        );

        allStars.push(eye(scrollContainer,
          -fog.width / 2 - 70,
          rocket.position.y - 400))
        allStars.push(eye(scrollContainer,
          fog.width / 2 + 70,
          rocket.position.y - 1200))
      } else {
        allStars.push(
          planet2(scrollContainer, -fog.width / 2 - 70, rocket.position.y - 600)
        );
      }

      allStars.push(
        planet3(scrollContainer, -fog.width / 2 - 70, rocket.position.y - 900)
      );
      allStars.push(
        planet4(scrollContainer, fog.width / 2 + 70, rocket.position.y - 1200)
      );
      allStars.push(
        planet5(
          scrollContainer,
          fog.width / 2 + 70,
          randRange(rocket.position.y - 1400, rocket.position.y - 1600)
        )
      );
      allStars.push(
        planet6(
          scrollContainer,
          -fog.width / 2 - 70,
          rocket.position.y - 1900
        )
      );

      // FOLLOW PLAYER
      app.ticker.add((delta) => {
        if (secondsElapsed - lastStarRepositionCheck > 1) {
          lastStarRepositionCheck = secondsElapsed;
          allStars.forEach((star) => {
            if (
              scrollContainer.position.y + star.position.y >
              STAR_SPAWN_OFFSET / 2.5
            ) {
              star.position.y -= STAR_SPAWN_OFFSET - randRange(0, 300);
            }
          });
        }
      });

      // EARTH
      const earthContainer = new PIXI.Container();
      const earth = addSprite("tex/earth.png", earthContainer, 0, 0, 1);
      earth.position.y = +earth.height / 2 + 30;
      earth.width = fog.width;
      if (earth.width < 400) {
        earth.width = 400;
      }
      app.ticker.add((delta) => {
        earth.width = fog.width;
      });

      if(branding) {
        addSprite(
          "tex/bet-construct/mr-first.png",
          earthContainer,
          -100,
          rocket.position.y - 18.5,
          1
        );
      }

      addSprite(
        "tex/rocketPlatform1.png",
        earthContainer,
        50,
        rocket.position.y - 18.5,
        0.5
      );
      addSprite(
        "tex/rocketPlatform2.png",
        earthContainer,
        -50,
        rocket.position.y + 11.5,
        0.5
      );
      const rectangle = PIXI.Sprite.from(PIXI.Texture.WHITE);
      rectangle.anchor.x = 0.5;
      rectangle.width = earth.width;
      rectangle.position.y = 500;
      rectangle.height = 1000;
      rectangle.tint = 0x141945;

      earthContainer.addChild(rectangle);
      scrollContainer.addChild(earthContainer);
    }

    function bahamutFish(scrollContainer, randomSkyPosX, randomSkyPosY) {
      const bahamutFishContainer = new PIXI.Container();
      bahamutFishContainer.position.set(0, randomSkyPosY);
      scrollContainer.addChild(bahamutFishContainer);

      const bahamutFish = addSprite(
        "tex/bet-construct/bahamut_fish.png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );

      breath(bahamutFish, bahamutFish.scale.x * 1.05, 1000);

      let gotoPosY = -100;
      let gotoTime = 32000;

      goto(bahamutFish, -randomSkyPosX, gotoPosY, gotoTime);

      bahamutFishContainer.addChild(bahamutFish);

      return bahamutFishContainer;
    }

    function eye(scrollContainer, randomSkyPosX, randomSkyPosY) {
      const planetContainer = new PIXI.Container();
      planetContainer.position.set(0, randomSkyPosY);
      scrollContainer.addChild(planetContainer);
      let eyeAnimationFrames = [];
      for (let i = 1; i < 38; i++) {
        eyeAnimationFrames.push(
          new PIXI.Texture.from("tex/bet-construct/eye/eye (" + i + ").png")
        );
      }

      let eye = new PIXI.AnimatedSprite(eyeAnimationFrames);
      eye.anchor.set(0.5)
      eye.position.set(randomSkyPosX,0);
      eye.scale.set(1.2)
      eye.loop = true;
      eye.animationSpeed = 0.2 ;
      eye.play();

      let gotoPosY = -200;
      let gotoTime = 20000;
      goto(eye, -randomSkyPosX, gotoPosY, gotoTime);
      breath(eye, eye.scale.x * 1.05, 3000);
      jiggle(eye, 10,3000);
      planetContainer.addChild(eye);

      return planetContainer;
    }

    function planet1(scrollContainer, randomSkyPosX, randomSkyPosY) {
      const planetContainer = new PIXI.Container();
      planetContainer.position.set(0, randomSkyPosY);
      scrollContainer.addChild(planetContainer);
      
      const planet1Glow = addSprite(
        "tex/planets/planet1/(1).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      const planet1 = addSprite(
        "tex/planets/planet1/(3).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      const planet1Ring = addSprite(
        "tex/planets/planet1/(2).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      planet1Ring.angle = 10;

      jiggle(planet1Ring, 10);
      breath(planet1Glow, planet1Glow.scale.x * 1.05, 1000);

      // let -randomSkyPosX = -fog.width/2-100
      let gotoPosY = -100;
      let gotoTime = 10000;
      goto(planet1Glow, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet1, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet1Ring, -randomSkyPosX, gotoPosY, gotoTime);

      planetContainer.addChild(planet1Glow);
      planetContainer.addChild(planet1);
      planetContainer.addChild(planet1Ring);

      return planetContainer;
    }

    function planet2(scrollContainer, randomSkyPosX, randomSkyPosY) {
      const planetContainer = new PIXI.Container();
      planetContainer.position.set(0, randomSkyPosY);
      scrollContainer.addChild(planetContainer);

      const planet2Glow = addSprite(
        "tex/planets/planet2/(1).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      const planet2 = addSprite(
        "tex/planets/planet2/(3).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      const planet2Ring = addSprite(
        "tex/planets/planet2/(2).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      planet2Ring.angle = 60;

      jiggle(planet2Ring, 20);
      breath(planet2, planet2.scale.x * 1.1, 1000);

      let gotoPosY = -200;
      let gotoTime = 14000;
      goto(planet2Glow, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet2, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet2Ring, -randomSkyPosX, gotoPosY, gotoTime);

      planetContainer.addChild(planet2Glow);
      planetContainer.addChild(planet2);
      planetContainer.addChild(planet2Ring);

      return planetContainer;
    }

    function planet3(scrollContainer, randomSkyPosX, randomSkyPosY) {
      const planetContainer = new PIXI.Container();
      planetContainer.position.set(0, randomSkyPosY);
      scrollContainer.addChild(planetContainer);

      const planet3 = addSprite(
        "tex/planets/planet3/(1).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      const planet3Ring = addSprite(
        "tex/planets/planet3/(3).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      const planet3Glow = addSprite(
        "tex/planets/planet3/(2).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      planet3Ring.angle = -30;

      jiggle(planet3Ring, 30);
      breath(planet3Ring, planet3Ring.scale.x * 1.1, 1000);

      let gotoPosY = -300;
      let gotoTime = 17000;
      goto(planet3Glow, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet3, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet3Ring, -randomSkyPosX, gotoPosY, gotoTime);

      planetContainer.addChild(planet3Glow);
      planetContainer.addChild(planet3);
      planetContainer.addChild(planet3Ring);

      return planetContainer;
    }

    function planet4(scrollContainer, randomSkyPosX, randomSkyPosY) {
      const planetContainer = new PIXI.Container();
      planetContainer.position.set(0, randomSkyPosY);
      scrollContainer.addChild(planetContainer);

      const planet4Glow = addSprite(
        "tex/planets/planet4/(2).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      const planet4 = addSprite(
        "tex/planets/planet4/(1).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      const planet4Ring2 = addSprite(
        "tex/planets/planet4/(4).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      const planet4Ring1 = addSprite(
        "tex/planets/planet4/(3).png",
        scrollContainer,
        randomSkyPosX,
        0 + 10,
        0.5
      );
      const planet4Ring3 = addSprite(
        "tex/planets/planet4/(5).png",
        scrollContainer,
        randomSkyPosX,
        0 + 22,
        0.5
      );
      planet4Ring2.angle = 1;
      planet4Ring1.angle = 0;
      planet4Ring3.angle = -1;

      jiggle(planet4Ring2, -2);
      // jiggle(planet4Ring2, 0)
      jiggle(planet4Ring3, 2);
      breath(planet4, planet4Glow.scale.x * 1.1, 1000);

      let gotoPosY = -100;
      let gotoTime = 12000;
      goto(planet4Glow, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet4, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet4Ring1, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet4Ring2, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet4Ring3, -randomSkyPosX, gotoPosY, gotoTime);

      planetContainer.addChild(planet4Glow);
      planetContainer.addChild(planet4);
      planetContainer.addChild(planet4Ring1);
      planetContainer.addChild(planet4Ring2);
      planetContainer.addChild(planet4Ring3);

      return planetContainer;
    }

    function planet5(scrollContainer, randomSkyPosX, randomSkyPosY) {
      const planetContainer = new PIXI.Container();
      planetContainer.position.set(0, randomSkyPosY);
      scrollContainer.addChild(planetContainer);

      const planet5 = addSprite(
        "tex/planets/planet5/(1).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      const planet5Ring1 = addSprite(
        "tex/planets/planet5/(2).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      const planet5Ring2 = addSprite(
        "tex/planets/planet5/(3).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );

      breath(
        planet5Ring1,
        planet5Ring1.scale.x * 1.2,
        650,
        TWEEN.Easing.Quadratic.InOut
      );
      setTimeout(function () {
        breath(
          planet5Ring2,
          planet5Ring2.scale.x * 1.2,
          650,
          TWEEN.Easing.Quadratic.InOut
        );
      }, 600);

      let gotoPosY = -200;
      let gotoTime = 42000;
      goto(planet5, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet5Ring1, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet5Ring2, -randomSkyPosX, gotoPosY, gotoTime);
      planetContainer.addChild(planet5);
      planetContainer.addChild(planet5Ring1);
      planetContainer.addChild(planet5Ring2);

      return planetContainer;
    }

    function planet6(scrollContainer, randomSkyPosX, randomSkyPosY) {
      const planetContainer = new PIXI.Container();
      planetContainer.position.set(0, randomSkyPosY);
      scrollContainer.addChild(planetContainer);

      const planet6 = addSprite(
        "tex/planets/planet6/(1).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      const planet6Ring = addSprite(
        "tex/planets/planet6/(2).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      const planet6Glow = addSprite(
        "tex/planets/planet6/(3).png",
        scrollContainer,
        randomSkyPosX,
        0,
        0.5
      );
      planet6Ring.angle = -10;

      jiggle(planet6Ring, 20);
      breath(planet6Ring, planet6Ring.scale.x * 1.1, 1000);
      breath(planet6, planet6.scale.x * 1.1, 1000);

      let gotoPosY = -300;
      let gotoTime = 8000;
      goto(planet6Glow, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet6, -randomSkyPosX, gotoPosY, gotoTime);
      goto(planet6Ring, -randomSkyPosX, gotoPosY, gotoTime);

      planetContainer.addChild(planet6Glow);
      planetContainer.addChild(planet6);
      planetContainer.addChild(planet6Ring);

      return planetContainer;
    }

    function breath(
      sprite,
      scale,
      time,
      easing = TWEEN.Easing.Sinusoidal.InOut
    ) {
      return new TWEEN.Tween(sprite.scale)
        .to({ x: scale, y: scale }, time)
        .easing(easing)
        .repeat(Infinity)
        .yoyo(true)
        .start();
    }

    function goto(sprite, x, y, time = 20000) {
      return new TWEEN.Tween(sprite.position)
        .to({ x: x, y: y }, time)
        .easing(TWEEN.Easing.Linear.None)
        .repeat(Infinity)
        .yoyo(true)
        .start();
    }

    function jiggle(sprite, amount = -15, time = 2000) {
      return new TWEEN.Tween(sprite)
        .to({ angle: sprite.angle + amount }, time)
        .easing(TWEEN.Easing.Sinusoidal.InOut)
        .repeat(Infinity)
        .yoyo(true)
        .start();
    }

    function randomChoice(arr) {
      return arr[Math.floor(Math.random() * arr.length)];
    }

    function randRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    function addSprite(texturePath, scrollContainer, posX, posY, scale) {
      const sprite = new PIXI.Sprite(PIXI.Texture.from(texturePath));
      sprite.position.set(posX, posY);
      sprite.anchor.set(0.5);
      sprite.scale.set(scale, scale);
      scrollContainer.addChild(sprite);
      return sprite;
    }

    function changeMp(newMp) {
      currMp = newMp;
      if (text) {
        text.style.fill = mpHexColorHelper(newMp)
        text.text = newMp;
      }
    }

    function predictMultiplier(stepsAhead) {
      return String(
        parseFloat(currMp * Math.pow(1 + 0.008, stepsAhead * 2.5)).toFixed(2)
      );
    }

    function parachuteAvatar(
      text,
      amount,
      labelXScale,
      positionX,
      positionY,
      avatarSrc
    ) {
      /**
       * Show parachuting avatar
       *
       * @param {string} text           - text above the parachuting avatar
       * @param {string} amount         - amount to show under the avatar, on the label
       * @param {float} labelXScale     - amount label width
       * @param {float} positionX       - x position of the flying avatar on the screen
       * @param {float} positionY       - y position of the flying avatar on the screen
       * @param {string} avatarSrc      - path of the img source
       */
      if (canvas.offsetParent == null) {
        return;
      }
      if (app.parachutingAvatars.length < 3) {
        const parachuteAvatarContainer = new PIXI.Container();
        parachuteAvatarContainer.scale.set(0);
        parachuteAvatarContainer.position.y = positionY;
        parachuteAvatarContainer.position.x = positionX;
        parachuteAvatarContainer.angle = -15;
        app.stage.addChild(parachuteAvatarContainer);

        app.ticker.add((delta) => {
          parachuteAvatarContainer.position.y -=
            (BACKGROUND_SPEED / 4) * delta;
        });
        if (branding) {
          const parachute = addSprite(
            "tex/bet-construct/parachute.png",
            parachuteAvatarContainer,
            0,
            -40,
            0.6
          );
        } else {
          const parachute = addSprite(
            "tex/parachute.png",
            parachuteAvatarContainer,
            0,
            -40,
            0.6
          );
        }
        
        const parachuteAvatar = addSprite(
          avatarSrc,
          parachuteAvatarContainer,
          0,
          0,
          1
        );
        parachuteAvatar.anchor.set(0.5);
        parachuteAvatar.width = 50;
        parachuteAvatar.height = 50;
        new TWEEN.Tween(parachuteAvatarContainer.scale)
          .to({ x: 0.8, y: 0.8 }, 600)
          .easing(TWEEN.Easing.Sinusoidal.InOut)
          .start();
        new TWEEN.Tween(parachuteAvatarContainer)
          .to({ angle: 15 }, 1000)
          .easing(TWEEN.Easing.Sinusoidal.InOut)
          .repeat(Infinity)
          .yoyo(true)
          .start();
        const parachuteAvatar_text = new PIXI.Text(text, {
          fontFamily: "Conv_TitilliumWeb-Bold",
          fontSize: 30,
          fill: 0xffffff,
          align: "center",
        });
        parachuteAvatar_text.position.set(0, -70);
        parachuteAvatar_text.anchor.set(0.5);
        parachuteAvatar_text.scale.set(0.5);
        parachuteAvatarContainer.addChild(parachuteAvatar_text);

        const parachuteAvatar_label = addSprite(
          "tex/parachuteAvatarLabel.png",
          parachuteAvatarContainer,
          0,
          35,
          0.5
        );
        parachuteAvatar_label.scale.x = labelXScale;
        const parachuteAvatar_label_text = new PIXI.Text(amount, {
          fontFamily: "Conv_TitilliumWeb-Bold",
          fontSize: 30,
          fill: 0xffffff,
          align: "center",
        });
        parachuteAvatar_label_text.position.set(0, 35);
        parachuteAvatar_label_text.anchor.set(0.5);
        parachuteAvatar_label_text.scale.set(0.5);
        parachuteAvatarContainer.addChild(parachuteAvatar_label_text);

        // make some vars for accessable for reuse
        parachuteAvatarContainer.parachuteAvatar = parachuteAvatar;
        parachuteAvatarContainer.parachuteAvatar_text = parachuteAvatar_text;
        parachuteAvatarContainer.parachuteAvatar_label_text =
          parachuteAvatar_label_text;
        parachuteAvatarContainer.parachuteAvatar_label =
          parachuteAvatar_label;

        app.parachutingAvatars.push(parachuteAvatarContainer);
      } else {
        // reuse old parachuteAvatarContainer
        const parachuteAvatarContainer = app.parachutingAvatars.shift();
        parachuteAvatarContainer.scale.set(0);
        parachuteAvatarContainer.position.y = positionY;
        parachuteAvatarContainer.position.x = positionX;
        new TWEEN.Tween(parachuteAvatarContainer.scale)
          .to({ x: 0.8, y: 0.8 }, 600)
          .easing(TWEEN.Easing.Sinusoidal.InOut)
          .start();
        parachuteAvatarContainer.parachuteAvatar.texture =
          PIXI.Texture.from(avatarSrc);
        parachuteAvatarContainer.parachuteAvatar_text.text = text;
        parachuteAvatarContainer.parachuteAvatar_label.scale.x = labelXScale;
        parachuteAvatarContainer.parachuteAvatar_label_text.text = amount;

        app.parachutingAvatars.push(parachuteAvatarContainer);
      }
    }
    app.parachuteAvatar = parachuteAvatar;

    function parachuteAvatarRetail(
      text,
      amount,
      labelXScale,
      positionX,
      positionY,
      avatarSrc
    ) {
      /**
       * Show parachuting avatar
       *
       * @param {string} text           - text above the parachuting avatar
       * @param {string} amount         - amount to show under the avatar, on the label
       * @param {float} labelXScale     - amount label width
       * @param {float} positionX       - x position of the flying avatar on the screen
       * @param {float} positionY       - y position of the flying avatar on the screen
       * @param {string} avatarSrc      - path of the img source
       */
      if (canvas.offsetParent == null) {
        return;
      }
      if (app.parachutingAvatars.length < 3) {
        const parachuteAvatarContainer = new PIXI.Container();
        parachuteAvatarContainer.scale.set(0);
        parachuteAvatarContainer.position.y = positionY;
        parachuteAvatarContainer.position.x = positionX;
        parachuteAvatarContainer.angle = -15;
        app.stage.addChild(parachuteAvatarContainer);

        app.ticker.add((delta) => {
          parachuteAvatarContainer.position.y -=
            (BACKGROUND_SPEED / 4) * delta;
        });

        var retailAvatarSprite = new PIXI.Sprite(retailAvatarTexture);
        retailAvatarSprite.position.set(0, 0);
        retailAvatarSprite.anchor.set(0.5);
        retailAvatarSprite.scale.set(1, 1);
        parachuteAvatarContainer.addChild(retailAvatarSprite);
        retailAvatarSprite.width = 50;
        retailAvatarSprite.height = 50;
        
        
        new TWEEN.Tween(parachuteAvatarContainer.scale)
          .to({ x: 0.8, y: 0.8 }, 600)
          .easing(TWEEN.Easing.Sinusoidal.InOut)
          .start();
        new TWEEN.Tween(parachuteAvatarContainer)
          .to({ angle: 15 }, 1000)
          .easing(TWEEN.Easing.Sinusoidal.InOut)
          .repeat(Infinity)
          .yoyo(true)
          .start();
        const parachuteAvatar_text = new PIXI.Text(text, {
          fontFamily: "Conv_TitilliumWeb-Bold",
          fontSize: 30,
          fill: 0xffffff,
          align: "center",
        });
        parachuteAvatar_text.position.set(0, -70);
        parachuteAvatar_text.anchor.set(0.5);
        parachuteAvatar_text.scale.set(0.5);
        parachuteAvatarContainer.addChild(parachuteAvatar_text);

        const parachuteAvatar_label = addSprite(
          "tex/parachuteAvatarLabel.png",
          parachuteAvatarContainer,
          0,
          35,
          0.5
        );
        parachuteAvatar_label.scale.x = labelXScale;
        const parachuteAvatar_label_text = new PIXI.Text(amount, {
          fontFamily: "Conv_TitilliumWeb-Bold",
          fontSize: 30,
          fill: 0xffffff,
          align: "center",
        });
        parachuteAvatar_label_text.position.set(0, 35);
        parachuteAvatar_label_text.anchor.set(0.5);
        parachuteAvatar_label_text.scale.set(0.5);
        parachuteAvatarContainer.addChild(parachuteAvatar_label_text);

        // make some vars for accessable for reuse
        parachuteAvatarContainer.parachuteAvatar = retailAvatarSprite;
        parachuteAvatarContainer.parachuteAvatar_text = parachuteAvatar_text;
        parachuteAvatarContainer.parachuteAvatar_label_text =
          parachuteAvatar_label_text;
        parachuteAvatarContainer.parachuteAvatar_label =
          parachuteAvatar_label;

        app.parachutingAvatars.push(parachuteAvatarContainer);
      } else {
        // reuse old parachuteAvatarContainer
        const parachuteAvatarContainer = app.parachutingAvatars.shift();
        parachuteAvatarContainer.scale.set(0);
        parachuteAvatarContainer.position.y = positionY;
        parachuteAvatarContainer.position.x = positionX;
        new TWEEN.Tween(parachuteAvatarContainer.scale)
          .to({ x: 0.8, y: 0.8 }, 600)
          .easing(TWEEN.Easing.Sinusoidal.InOut)
          .start();
        //parachuteAvatarContainer.parachuteAvatar.texture = PIXI.Texture.from('/retail-avatars/avatar-1.png');
        parachuteAvatarContainer.parachuteAvatar = retailAvatarSprite;
        parachuteAvatarContainer.parachuteAvatar_text.text = text;
        parachuteAvatarContainer.parachuteAvatar_label.scale.x = labelXScale;
        parachuteAvatarContainer.parachuteAvatar_label_text.text = amount;

        app.parachutingAvatars.push(parachuteAvatarContainer);
      }
    }
    app.parachuteAvatarRetail = parachuteAvatarRetail;

    function hideParachuteAvatars() {
      if (canvas.offsetParent == null) {
        return;
      }
      for (const parachutingAvatarContainer of app.parachutingAvatars) {
        parachutingAvatarContainer.position.y = 2000;
      }
    }
    app.hideParachuteAvatars = hideParachuteAvatars;
  }
}

let assetsLoaded = false;
let pixiView = null;
let intervalAttemptInit = null

export default class PixiComponent extends React.Component {
  constructor() {
    super();
    window.PixiComponent = this;
  }

  attemptInit(intervalAttemptInit){
    if (window.innerWidth > 0 && this.gameCanvas && this.gameCanvas.offsetParent){
      pixiView = new PixiView(this);
      clearInterval(intervalAttemptInit);
    }else{
      console.error("GameView waiting")
    }
  }

  /**
   * After mounting, add the Pixi Renderer to the div and start the Application.
   */
  componentDidMount() {
    if (pixiView != null) {
      console.log("Destroying existing GameView")
      try{
        pixiView.app.destroy();
        pixiView = null
      }catch{console.error("Something went wrong when destroying GameView")}
    }
    if (intervalAttemptInit){
      clearInterval(intervalAttemptInit)
    }
    intervalAttemptInit = setInterval(function(context){context.attemptInit(intervalAttemptInit);}, 300, this)
  }

  /**
   * Stop the Application when unmounting.
   */
  componentWillUnmount() {
    if (pixiView != null){
      console.log("Resetting app loader")
      try{
        pixiView.app.loader.reset();
      }catch{console.error("Something went wrong when reseting app loader")}

      console.log("Destroying existing GameView")
      try{
        pixiView.app.destroy();
        pixiView = null
      }catch{console.error("Something went wrong when destroying GameView")}
    }
  }

  promoAstronaut(text, amount, labelXScale = 1) {
    /**
     * Show promo astronaut
     *
     * @param {string} text
     * @param {string} amount
     * @param {int} labelXScale
     */
    try{
      if (pixiView?.app?.astronautContainer) {
        pixiView.app.astronautContainer.position.y = -window.innerHeight / 2 - 50;
        pixiView.app.astronaut_text.text = text;
        pixiView.app.astronaut_label.scale.x = labelXScale;
        pixiView.app.astronaut_label_text.text = amount;
      }
    }catch{console.error("promoAstronaut failed")}
  }

  parachuteAvatar(
    text,
    amount,
    labelXScale = 1,
    positionX,
    positionY,
    avatarSrc
  ) {
    /**
     * Show parachuting avatar
     *
     * @param {string} text           - text above the parachuting avatar
     * @param {string} amount         - amount to show under the avatar, on the label
     * @param {float} labelXScale     - amount label width
     * @param {float} positionX       - x position of the flying avatar on the screen
     * @param {float} positionY       - y position of the flying avatar on the screen
     * @param {string} avatarSrc      - path of the img source
     */
    try{
      pixiView.app.parachuteAvatar(
        text,
        amount,
        (labelXScale = 1),
        positionX,
        positionY,
        avatarSrc
      );
    }catch{console.error("parachuteAvatar failed")}
  }

  parachuteAvatarRetail(
    text,
    amount,
    labelXScale = 1,
    positionX,
    positionY,
    avatarSrc
  ) {
    /**
     * Show parachuting avatar
     *
     * @param {string} text           - text above the parachuting avatar
     * @param {string} amount         - amount to show under the avatar, on the label
     * @param {float} labelXScale     - amount label width
     * @param {float} positionX       - x position of the flying avatar on the screen
     * @param {float} positionY       - y position of the flying avatar on the screen
     * @param {string} avatarSrc      - path of the img source
     */
    try{
      pixiView.app.parachuteAvatarRetail(
        text,
        amount,
        (labelXScale = 1),
        positionX,
        positionY,
        avatarSrc
      );
    }catch(error){console.error("parachuteAvatarRetail failed", error)}
  }

  hideParachuteAvatars() {
    try{
      pixiView.app.hideParachuteAvatars();
    }catch{console.error("hideParachuteAvatars failed")}
  }

  /**
   * Simply render the div that will contain the Pixi Renderer.
   */
  render() {
    let component = this;
    return (
      <div
        id="mainGameId"
        ref={(thisDiv) => {
          component.gameCanvas = thisDiv;
        }}
      />
    );
  }
}
