import React, { useMemo, useState } from "react";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import AllBetsTrRetail from "../AllBetsTrRetail";
import { useRetailTicketsContext } from "../../contexts/RetailTickets";

const AllShopsTab = () => {
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);
  //const [retailTickets, setRetailTickets] = useState([]);
  //const [render,setRender] = useState(false);

  // window.setAllBetsTabLang = setLanguage;

  // window.setRetailTickets = (data) => {
  //   setRender(!render);
  //   setRetailTickets(data);
  //   window.setRetailTicketsLenght(data.length);
  // }
  const retailTicketContext = useRetailTicketsContext();
  const retailTickets = retailTicketContext.retailTickets;

  const shopsRender = useMemo(() => (
      <table className="bets-table">
        <thead>
          <tr className={"table-header " + (rocketmanTransport.r7css ? "r7css" : "")}>
            {/* <th>{translate('user')}</th> */}
            <th>{translate('shop')}</th>
            <th>{translate('bet_bets_tab')}</th>
            <th>{translate('coeff')}</th>
            <th>{translate('win')}</th>
          </tr>
        </thead>
        <tbody>
          {retailTickets.map((ticket, index) => {
              return  (
                <AllBetsTrRetail
                  key={index}
                  index={index}
                  //avatar={`avatar${ticket.avatar_id}`}
                  username={ticket.Shop}
                  flagCode={ticket?.CountryCode2Char == 'XK' && language === 'sr' ? 'rs' : ticket?.CountryCode2Char}
                  bet={rocketmanTransport?.myCurrency?.toUpperCase() === ticket?.CurrencyID?.toUpperCase() ? ticket?.Stake : ticket?.StakeEUR * rocketmanTransport?.myExchangeRate}
                  coefficient={ticket.Multiplier}
                  win={ticket?.Win}
                  valute={rocketmanTransport.myCurrency}
                  //isVip={ticket.avatar_id > 49 ? true : false}
                />
              )
            })}
        </tbody>
      </table>
  ),[retailTickets,translate])

  return shopsRender;
};

export default AllShopsTab;
