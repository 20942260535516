import React, { useEffect, useState } from "react";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import ElbetLogoLoader from "../svg/elbet-logo-loader";
import ElbetLogoLoaderLower from "../svg/elbet-logo-loader-lower";
import preloadAvatars from "../../services/Helpers/PreloadAvatars";

const MAX_TIME_LOADING_ERR = 30;

const LoadingScreen = ({gameStarted, warning}) => {

  const [timeUnderLoading, setTimeUnderLoading] = useState(0);
  const [freeToSendError, setFreeToSendError] = useState(true);

  useEffect(() => {
    if (!gameStarted && freeToSendError) {
      var loadingInterval = setInterval(() => {
        setTimeUnderLoading(timeUnderLoading + 1);

        if (timeUnderLoading > MAX_TIME_LOADING_ERR) {
          //rocketmanTransport.sendErrorLogToServer("LONG LOADING", `LOADING TOOK MORE THAN  ${MAX_TIME_LOADING_ERR} seconds`);
          clearInterval(loadingInterval);
          setTimeUnderLoading(0);
          setFreeToSendError(false);
        }
      }, 1000);
    } else {
      clearInterval(loadingInterval);
      setTimeUnderLoading(0);
    }

    return () => clearInterval(loadingInterval);
  },[gameStarted,timeUnderLoading,freeToSendError]);

  //preloadAvatars();

  return (
    <div className={["loading-screen", gameStarted ? "hide" : ""].join(" ")} >
      <ElbetLogoLoader />
      <p className="loading-screen-text">{translate('loading')} <span className="dot-pulse"></span></p>
      <p className="loading-screen-text">{warning} </p>
      <div className="loading-screen-footer">
        From
        <ElbetLogoLoaderLower />
      </div>
    </div>
  );
};

export default LoadingScreen;
