import React, { useEffect, useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BoosterPotHolder from "./BetComponent/Header/BoosterPotHolder";
import JackpotHolder from "./BetComponent/Header/JackpotHolder";
import RocketPotHolder from "./BetComponent/Header/RocketPotHolder";
import rocketmanTransport, { urlParams } from "../services/RocketmanTransport";
import translate from "../services/Translation/lang";
import JackpotTableTr from "./JackpotTableTr";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";

const REQUEST_JACKPOT_INTERVAL = 30000;

const JackpotsHeader = (props) => {
  const [initialGameStart, setInitialGameStart] = useState(true);
  const [showJacketpot, setShowJacketpot] = useState(true);
  const [showRocketpot, setShowRocketpot] = useState(true);
  const [showBoosterpot, setShowBoosterpot] = useState(true);
  const [jackpotHistory, setJackpotHistory] = useState([]);
  const [rocketPotHistory, setRocketPotHistory] = useState([]);
  const [boosterPotHistory, setBoosterPotHistory] = useState([]);
  const [headerJackpotData, setHeaderJackpotData] = useState({});

  window.setInitialGameStart = setInitialGameStart;
  window.setJackpotHistory = setJackpotHistory;
  window.setRocketPotHistory = setRocketPotHistory;
  window.setBoosterPotHistory = setBoosterPotHistory;
  window.setHeaderJackpotData = setHeaderJackpotData;

  const handleJacketpot = () => {
    if (!rocketmanTransport.retail) {
      setShowJacketpot(!showJacketpot);
      if (!showRocketpot) {
        setShowRocketpot(!showRocketpot);
      }
      if (!showBoosterpot) {
        setShowBoosterpot(!showBoosterpot);
      }
      if (showJacketpot) {
        rocketmanTransport.requestJackpotHistory(1);
      }
    }
  };
  const handleRocketpot = () => {
      if (!rocketmanTransport.retail) {
      setShowRocketpot(!showRocketpot);
      if (!showJacketpot) {
        setShowJacketpot(!showJacketpot);
      }
      if (!showBoosterpot) {
        setShowBoosterpot(!showBoosterpot);
      }
      if (showRocketpot) {
        rocketmanTransport.requestJackpotHistory(2);
      }
    }
  };

  const handleBoosterpot = () => {
      if (!rocketmanTransport.retail) {
      setShowBoosterpot(!showBoosterpot);
      if (!showJacketpot) {
        setShowJacketpot(!showJacketpot);
      }
      if (!showRocketpot) {
        setShowRocketpot(!showRocketpot);
      }
      if (showBoosterpot) {
        rocketmanTransport.requestJackpotHistory(3);
      }
    }
  };

  const jacketpotRef = useRef(null);
  const rocketpotRef = useRef(null);
  const boosterpotRef = useRef(null);
  const [clickedOutside, setClickedOutside] = useState(false);

  const handleClickOutside = (e) => {
    if (jacketpotRef.current && !jacketpotRef?.current.contains(e.target)) {
      setClickedOutside(true);
      if (!showJacketpot) {
        setShowJacketpot(!showJacketpot);
      }
    }
    if (rocketpotRef.current && !rocketpotRef?.current.contains(e.target)) {
      setClickedOutside(true);
      if (!showRocketpot) {
        setShowRocketpot(!showRocketpot);
      }
    }
    if (boosterpotRef.current && !boosterpotRef?.current.contains(e.target)) {
      setClickedOutside(true);
      if (!showBoosterpot) {
        setShowBoosterpot(!showBoosterpot);
      }
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    rocketmanTransport.requestJackpotData(rocketmanTransport.launcherCompanyId);
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const refreshJackpotValues = () => {
    rocketmanTransport.requestJackpotData(rocketmanTransport.launcherCompanyId);
    setInitialGameStart(false);
  };

  useEffect(() => {
    var refreshJackpotInterval = setInterval(() => {
      refreshJackpotValues();
    }, REQUEST_JACKPOT_INTERVAL);

    return () => clearInterval(refreshJackpotInterval);
  }, []);
  return (
    <div className={"jackpots-header " + (rocketmanTransport.retail ? "retail" : " ")}>
      <div className="container">
        <Row className="gutter-3">
          {headerJackpotData?.jackpot ? (
            <Col
              className="d-flex justify-content-center"
              ref={jacketpotRef}
              onClick={handleClickInside}
            >
              <div
                className={
                  "jackpot-holder " +
                  (props.jackpotActive ? "jackpot-active " : " ") +
                  (initialGameStart ? "on-load " : " ") + 
                  (rocketmanTransport.r7css ? "r7css" : "")
                }
                onClick={handleJacketpot}
              >
                <JackpotHolder />
              </div>
            </Col>
          ) : null}
          {headerJackpotData?.rocketpot ? (
            <Col
              className="d-flex justify-content-center"
              ref={rocketpotRef}
              onClick={handleClickInside}
            >
              <div
                className={
                  "jackpot-holder " +
                  (props.rocketpotActive ? "jackpot-active " : " ") +
                  (initialGameStart ? "on-load " : " ") +
                  (rocketmanTransport.r7css ? "r7css" : "")
                }
                onClick={handleRocketpot}
              >
                <RocketPotHolder />
              </div>
            </Col>
          ) : null}
          {headerJackpotData?.boosterpot ? (
            <Col
              className="d-flex justify-content-center"
              ref={boosterpotRef}
              onClick={handleClickInside}
            >
              <div
                className={
                  "jackpot-holder " +
                  (props.boosterpotActive ? "jackpot-active " : " ") +
                  (initialGameStart ? "on-load " : " ") +
                  (rocketmanTransport.r7css ? "r7css" : "")
                }
                onClick={handleBoosterpot}
              >
                <BoosterPotHolder />
              </div>
            </Col>
          ) : null}
        </Row>
      </div>
    </div>
  );
};

export default JackpotsHeader;
